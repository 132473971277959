// custom theme colors

@brand-primary:              	#004B87;
@brand-success:         		#009F4D;
@brand-info:            		#007FA3;
@brand-warning:         		#ff9800;
@brand-danger:          		#e51c23;


//question sections type background colors

@prescribing-item:              #009F4D;
@prescription-review-item:      #14B1EC;
@planning-management-item:      #A02C2C;
@communicating-info:            #2E9EB0;
@adverse-Drug:                  rgb(179, 175, 56); 
@drug-monitoring-item:          #DE890C;
@data-interp-item:              #9E9797;
@calculation-skills-item:       #DEDEDE;

//questions type colors

@text-white:                    #ffffff;

//top margin for table
@table-top-margin:                 20px;

//border radius 
@border-radius-none:                0px;

