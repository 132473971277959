

@font-face {
    font-family: 'fs_trumanregular';
    src: url('../fonts/fstruman-regular-webfont.eot');
    src: url('../fonts/fstruman-regular-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/fstruman-regular-webfont.woff2') format('woff2'),
         url('../fonts/fstruman-regular-webfont.woff') format('woff'),
         url('../fonts/fstruman-regular-webfont.ttf') format('truetype'),
         url('../fonts/fstruman-regular-webfont.svg#fs_trumanregular') format('svg');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'fs_trumanlight_italic';
    src: url('../fonts/fstruman-lightitalic-webfont.eot');
    src: url('../fonts/fstruman-lightitalic-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/fstruman-lightitalic-webfont.woff2') format('woff2'),
         url('../fonts/fstruman-lightitalic-webfont.woff') format('woff'),
         url('../fonts/fstruman-lightitalic-webfont.ttf') format('truetype'),
         url('../fonts/fstruman-lightitalic-webfont.svg#fs_trumanlight_italic') format('svg');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'fs_trumanlight';
    src: url('../fonts/fstruman-light-webfont.eot');
    src: url('../fonts/fstruman-light-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/fstruman-light-webfont.woff2') format('woff2'),
         url('../fonts/fstruman-light-webfont.woff') format('woff'),
         url('../fonts/fstruman-light-webfont.ttf') format('truetype'),
         url('../fonts/fstruman-light-webfont.svg#fs_trumanlight') format('svg');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'fs_trumanitalic';
    src: url('fstruman-italic-webfont.eot');
    src: url('fstruman-italic-webfont.eot?#iefix') format('embedded-opentype'),
         url('fstruman-italic-webfont.woff2') format('woff2'),
         url('fstruman-italic-webfont.woff') format('woff'),
         url('fstruman-italic-webfont.ttf') format('truetype'),
         url('fstruman-italic-webfont.svg#fs_trumanitalic') format('svg');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'fs_trumanbold_italic';
    src: url('fstruman-bolditalic-webfont.eot');
    src: url('fstruman-bolditalic-webfont.eot?#iefix') format('embedded-opentype'),
         url('fstruman-bolditalic-webfont.woff2') format('woff2'),
         url('fstruman-bolditalic-webfont.woff') format('woff'),
         url('fstruman-bolditalic-webfont.ttf') format('truetype'),
         url('fstruman-bolditalic-webfont.svg#fs_trumanbold_italic') format('svg');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'fs_trumanbold';
    src: url('fstruman-bold-webfont.eot');
    src: url('fstruman-bold-webfont.eot?#iefix') format('embedded-opentype'),
         url('fstruman-bold-webfont.woff2') format('woff2'),
         url('fstruman-bold-webfont.woff') format('woff'),
         url('fstruman-bold-webfont.ttf') format('truetype'),
         url('fstruman-bold-webfont.svg#fs_trumanbold') format('svg');
    font-weight: normal;
    font-style: normal;

}