@import "customVariables.less";

.btn{
    border-radius: @border-radius-none;
}
.prescribing-item {
        background-color: @prescribing-item !important;
        color: @text-white !important;
}
.prescription-review-item {
      background-color: @prescription-review-item !important;
      color: @text-white !important;
}
.planning-management-item {
      background-color: @planning-management-item !important;
      color: @text-white !important;
}
.communicating-info {
      background-color: @communicating-info !important;
      color: @text-white !important;
}
.adverse-Drug {
    background-color: @adverse-Drug !important;
    color: @text-white !important;
} 
.drug-monitoring-item {
    background-color: @drug-monitoring-item !important;
    color: @text-white !important;
}
.data-interp-item{
    background-color: @data-interp-item !important;
    color: @text-white !important;
}
.calculation-skills-item {
    background-color: @calculation-skills-item !important;
    /*color: #fff !important;*/
}